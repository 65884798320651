import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import TableSortLabel from '@mui/material/TableSortLabel';

const rows = [
  { id: 'name', label: 'Name' },
  { id: 'age', label: 'Age' },
  // Add more columns as needed
];

function MyTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');

  const handleRequestSort = (property) => {
    console.log(property)
    console.log(orderBy)
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {rows.map((row) => (
              <TableCell key={row.id}>
                <TableSortLabel
                //   active={orderBy === row.id}
                  direction={orderBy === row.id ? order : 'asc'}
                  onClick={() => handleRequestSort(row.id)}
                >
                  {row.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {/* Add table rows here */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default MyTable;
