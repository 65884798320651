import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import axios from 'axios';
import Config from '../../config/config'
import { DataGrid } from '@mui/x-data-grid';


export default function MultilineTextFields(props) {


    const [apiColumns, setApiColumns] = useState([])
    const [apiRows, setApiRows] = useState([])


    useEffect(() => {

        fetchReport(props.api)
    }, [])

    const toTitle = (input) => {
        return input.replaceAll('_', ' ').toUpperCase()
    }

    const calculateWidth = (input) => {
        let width = 100
        if (input === 'imei') {
            width = 140
        } else if (input.toLowerCase().includes("shop_name")) {
            width = 220
        } else if (input.toLowerCase().includes("mobile")) {
            width = 100
        } else {
            width = input.length * 15
        }
        return width
    }

    const columnVisibility = (input) => {
        let hiddenColumns = ['prod_link', 'shop_link']
        let showColumn = true
        if (hiddenColumns.includes(input)) {
            showColumn = false
        }
        return showColumn
    }

    const customDateSort = (a, b) => {
        const dateA = new Date(a.split_date && a.split_date.split('/').reverse().join('/'));
        const dateB = new Date(b.split_date && b.split_date.split('/').reverse().join('/'));

        return dateB - dateA;
    };

    const fetchReport = async (api) => {

        axios.get(`${Config.api_endpoint}/${api}`).then(function (response) {
            if (response.data.data) {
                let columnValues = []
                console.log(response.data.data)
                let apiResData = response.data.data
                console.log(new Date(response.data.data[0].added_date))
                console.log(new Date(response.data.data[0].added_date) - new Date(response.data.data[1].added_date))
                apiResData = apiResData.sort(customDateSort)
                console.log(apiResData)
                response.data && apiResData && Object.keys(apiResData[0]).map((item) => {
                    console.log(item)
                    if (columnVisibility(item)) {
                        let json = {
                            field: item,
                            headerName: toTitle(item),
                            width: calculateWidth(item),
                        }
                        columnValues.push(json)
                    }
                })
                setApiColumns(columnValues)

                let rowValues = []
                response.data && apiResData && apiResData.map((item, index) => {
                    let json = {
                        id: index,
                        ...item
                    }
                    rowValues.push(json)
                })
                console.log(rowValues)
                setApiRows(rowValues)
            }

        }).catch(function (error) {
            console.log(error);
        });

    }

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
        >
            {props.label}

            <DataGrid
                rows={apiRows}
                columns={apiColumns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                pageSizeOptions={[5]}
                // checkboxSelection
                disableRowSelectionOnClick
            />

        </Box>
    );
}