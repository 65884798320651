import React, { useEffect, useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import Config from '../../config/config'
import { Typography, IconButton, TextField, Stack, Button, Divider, Alert } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { format } from 'date-fns';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Loader from '../General/Loader'

export default function MultilineTextFields() {

    const [apiColumns, setApiColumns] = useState([])
    const [apiRows, setApiRows] = useState([])

    const [shopDropdown, setShopDropdown] = useState([])
    const [imeiInput, setImeiInput] = useState('')
    const [shopId, setShopId] = useState('')
    const [shop, setShop] = useState({})

    const [imeiVerifyList, setImeiVerifyList] = useState([])
    const [selectedDate, setSelectedDate] = useState(format(new Date(), 'yyyy-MM-dd'))
    const [severity, setSeverity] = useState('')
    const [openAlert, setOpenAlert] = useState(false)
    const [apiResMsg, setApiResMsg] = useState('')
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getShopList()
    }, [])

    const clearAllData = () => {
        setImeiInput('')
        setSelectedDate(format(new Date(), 'yyyy-MM-dd'))
        setShopId('')
        setShop({})
        setImeiVerifyList([])
    }

    const toTitle = (string) => {
        return string.replaceAll('_', ' ').toUpperCase()
    }

    const getShopList = () => {
        axios.get(`${Config.api_endpoint}/shops`).then(function (response) {
            console.log(response);
            setShopDropdown(response.data.data)
            // setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const handleReturn = () => {
        let date = selectedDate.split('-')
        let formattedDate = date[2] + '/' + date[1] + '/' + date[0]

        let payload = {
            "stock_info": {
                "imei": imeiInput,
                "date": formattedDate
            }
        }

        axios.post(`${Config.api_endpoint}/stock/return`, payload).then((res) => {
            console.log(res)
            setOpenAlert(true)
            setSeverity('success')
            setApiResMsg('Returned successfully')
            clearAllData()
        }).catch((error) => {
            setOpenAlert(true)
            setSeverity('error')
            setApiResMsg(error.response.data.error)
        })



    }

    const handleMigration = () => {
        let date = selectedDate.split('-')
        let formattedDate = date[2] + '/' + date[1] + '/' + date[0]

        let payload = {
            "location_info": {
                "imei": imeiInput,
                "new_shop_id": shopId,
                "old_shop_id": imeiVerifyList[0].id,
                "relocated_date": formattedDate
            }
        }

        axios.put(`${Config.api_endpoint}/stock/move`, payload).then((res) => {
            console.log(res)
            setOpenAlert(true)
            setSeverity('success')
            setApiResMsg('Migrated successfully')
            clearAllData()
        }).catch((error) => {
            setOpenAlert(true)
            setSeverity('error')
            setApiResMsg(error.response.data.error)
        })
    }

    const handleImeiVerify = () => {
        setIsLoading(true)
        axios.post(`${Config.api_endpoint}/imeiverify`, {
            imeiList: [imeiInput]
        }).then((response) => {
            console.log(response);
            setImeiVerifyList(response.data.data)

            setIsLoading(false)

            let columnValues = []
            response.data && response.data.data && Object.keys(response.data.data[0]).map((item) => {
                console.log(item)
                let json = {
                    field: item,
                    headerName: toTitle(item),
                    width: (item === 'imei' || item === 'name') ? 170 : item.length * 15,
                    resizable: true
                }
                columnValues.push(json)
            })
            console.log(columnValues)
            setApiColumns(columnValues)

            let rowValues = []
            response.data && response.data.data && response.data.data.map((item, index) => {
                let json = {
                    id: index,
                    ...item
                }
                rowValues.push(json)
            })
            console.log(rowValues)
            setApiRows(rowValues)

        }).catch((error) => {
            console.log(error);
        });

    }

    const handleShopChange = (e) => {
        setShopId(e.target.value.id)
        setShop(e.target.value)
    }

    const handleDateChange = (e) => {
        setSelectedDate(e.target.value)
    }

    return (
        isLoading ?
            <Loader /> :
            <Box component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >
                {openAlert ? <Alert severity={severity}>{apiResMsg}</Alert> : <></>}
                <Typography variant='h5' sx={{ paddingBottom: '15px' }}>Returns</Typography>

                <Stack direction='row' justifyContent='space-evenly' divider={<Divider orientation="vertical" flexItem />}>
                    <>
                        <Typography sx={{ paddingBottom: '15px' }}>Enter IMEI</Typography>
                        <TextField id="imei-text" label="IMEI" value={imeiInput} variant="outlined" onChange={(e) => setImeiInput(e.target.value)} />

                        <IconButton size='large' onClick={handleImeiVerify}>
                            <CheckCircleOutlineIcon fontSize='large' />
                        </IconButton>
                    </>

                    <>
                        <Typography sx={{ paddingBottom: '15px' }}>Select Migration Details</Typography>
                        <Stack direction='row' spacing={2}>
                            <FormControl variant="standard" sx={{ height: '15px' }}>
                                <InputLabel id="demo-simple-select-label">Select Shop</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"

                                    label="Age"

                                    value={shop}
                                    onChange={handleShopChange}
                                    style={{ width: '200px', height: '35px' }}
                                    placeholder='Select Shop'
                                >
                                    {shopDropdown.map((item) => (
                                        <MenuItem value={item}>{item.id}. {item.name} / {item.address}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <input type="date" value={selectedDate}
                                onChange={handleDateChange}
                                style={{ width: '100px', height: '35px' }} />
                            <Button onClick={handleMigration} variant='contained' sx={{ alignContent: 'right', height: '35px' }}>Migrate</Button>
                        </Stack>
                    </>
                </Stack>

                {imeiVerifyList && imeiVerifyList.length > 0 &&
                    <>
                        <DataGrid
                            rows={apiRows}
                            columns={apiColumns}
                            pageSizeOptions={[5]}
                            disableRowSelectionOnClick
                            isColumnResizing
                        />
                        <Stack direction='row' spacing={2}>
                            <Button onClick={handleReturn} variant='contained' sx={{ alignContent: 'right', background: 'red' }}>Take Return</Button>
                        </Stack>
                    </>
                }

            </Box>
    );
}
