import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TextField, FormControl, FormLabel, Typography } from '@mui/material';
import { Button } from '@mui/material';
import AddShopJson from '../utils/addshop.json'
import axios from 'axios'
import Config from '../config/config'
import { IconButton } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function MultilineTextFields() {

    const [output, setOutput] = useState([])

    useEffect(() => {
        let outputTemp = {}
        AddShopJson.map((item) => {
            outputTemp[item.key] = ''
        })
        setOutput(outputTemp)
    }, [])

    const saveShopDetails = () => {
        console.log(output)
        axios.post(`${Config.api_endpoint}/add/shop`, {
            shop_info: { ...output }
        }).then(function (response) {
            console.log(response);
            window.location.reload(true)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const handleInputChange = (value, key) => {
        console.log(value, key)
        let shopDetails = { ...output }
        shopDetails[key] = value
        setOutput(shopDetails)
    }

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
        >

            {/* <FormControl> */}

                {/* {AddShopJson.map((row) => (
                    <>
                        <FormLabel>{row.label}</FormLabel>
                        <TextField
                            id={row.key}
                            label={row.label}
                            placeholder={row.placeholder}
                            value={output[row.key]}
                            size='small'
                        />
                    </>
                ))} */}
                <Typography variant='h5' sx={{paddingBottom: '15px'}}>Add shop details</Typography>

                <TableContainer component={Paper}>
                    <Table sx={{ width: '100%' }} aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                {AddShopJson.map((row) => (
                                    <TableCell>
                                        <TextField
                                            id={row.key}
                                            label={row.label}
                                            placeholder={row.placeholder}
                                            value={output[row.key]}
                                            size='small'
                                            onChange={(e) => handleInputChange(e.target.value, row.key)}
                                            style={{ width: '300px' }}
                                        />
                                    </TableCell>
                                ))}
                                <TableCell>
                                    <IconButton size='large' onClick={saveShopDetails}>
                                        <CheckCircleOutlineIcon fontSize='large' />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>


                {/* <Button onClick={saveShopDetails}>Submit</Button> */}
            {/* </FormControl> */}
        </Box>
    );
}