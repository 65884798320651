import logo from './logo.svg';
import './App.css';
import Sidebar from './components/Sidebar'
import Tabless from './components/BasicTable'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

function App() {
  return (
    <Router>
      <Sidebar>
        
      </Sidebar>
    </Router>
  );
}

export default App;
