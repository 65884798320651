import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import MailIcon from '@mui/icons-material/Mail';
import { useNavigate } from 'react-router-dom';
import BasicTable from './BasicTable';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import AddProduct1 from './AddProduct1';
import Login from './Login/Login';
import TableSortCustom from './TableSort';
import AddShop from './AddShop';
import ProductAdding from './ProductAdding';
import ShopSplit from './Shop/ShopSplit'
import StockSold from './Shop/StockSold'
import Reports from './Reports/Reports'
import Returns from './Returns/Returns'
import SpecificReport from './Reports/SpecificReport'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PostAddIcon from '@mui/icons-material/PostAdd';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';


const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function MiniDrawer(props) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [isLogged, setIsLogged] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    localStorage.clear()
    navigate('/')
  };

  const navigate = useNavigate()

  const menuItems = [
    {
      path: "/addproduct",
      name: "Stock Addition",
      icon: <PostAddIcon />,
      isSelected: true
    },
    {
      path: "/stock/shop",
      name: "Stock Split",
      icon: <ArrowCircleRightIcon />,
      isSelected: true
    },
    {
      path: "/stock/sold",
      name: "Stock Sold",
      icon: <CheckBoxIcon />,
      isSelected: true
    },
    {
      path: "/returns",
      name: "Returns",
      icon: <AssignmentReturnIcon />,
      isSelected: true
    },
    {
      path: "/specificreport",
      name: "Shop Summary",
      icon: <AutoAwesomeMosaicIcon />,
      isSelected: true
    },
    {
      path: "/reports",
      name: "Reports",
      icon: <ReceiptIcon />,
      isSelected: true
    }
  ]

  useEffect(() => {
    console.log('sidebar')
    console.log(localStorage.getItem('isLogged'))
    setIsLogged(localStorage.getItem('isLogged'))
  })

  return (
    isLogged ?
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Sri Senthur Traders
            </Typography>
            <Button variant="contained" disableRipple
              sx={{ mt: 3, mb: 2, backgroundColor: 'orange' }} style={{ marginLeft: 'auto' }}
              onClick={handleLogout}>
              Logout
            </Button>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {menuItems.map((item, index) => (
              <ListItem key={item.name} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                  onClick={() => { navigate(item.path) }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.name} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{ flexGrow: 1, padding: 10, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Routes>
            <Route path='/' element={<Reports />} />
            <Route path='/addshop' element={<AddShop />} />
            <Route path='/addsales' element={<TableSortCustom />} />
            <Route path='/addproduct' element={<AddProduct1 />} />
            <Route path='/productadding' element={<ProductAdding />} />
            <Route path='/stock/shop' element={<ShopSplit />} />
            <Route path='/stock/sold' element={<StockSold />} />
            <Route path='/reports' element={<Reports />} />
            <Route path='/returns' element={<Returns />} />
            <Route path='/specificreport' element={<SpecificReport />} />
            <Route path='/login' element={<Login />} />

          </Routes>
        </Box>
      </Box> :
      <Login />
  );
}