import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import Config from '../../config/config'

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Loader from '../General/Loader'
import './Reports.css'
import { Grid } from '@mui/material';
import SpecificReportTable from './SpecificReportTable'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';


export default function SpecificReport() {

    const initialReport = 'shopwiseStock'
    const [isLoading, setIsLoading] = useState(false)
    const [shopDropdown, setShopDropdown] = useState([])
    const [shop, setShop] = useState({})
    const [shopId, setShopId] = useState(0)
    const [stockValueInfo, setStockValueInfo] = useState({})
    const [tabValue, setTabValue] = React.useState(initialReport);


    useEffect(() => {

        const queryParams = new URLSearchParams(window.location.search);
        let shop_id = queryParams.get('shop_id');
        let type = queryParams.get('reportType')
        type = type && type.length > 0 ? type : 'shopwiseStock'
        setTabValue(type)
        getShopList(shop_id)

    }, [])

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const getShopList = (shop_id) => {
        setIsLoading(true)
        axios.get(`${Config.api_endpoint}/shops`).then(function (response) {
            console.log(response);
            setShopDropdown(response.data.data)
            setIsLoading(false)
            console.log(shop_id)
            console.log(response.data.data);
            let res = response.data.data
            shop_id = shop_id && shop_id.length > 0 ? shop_id : response.data.data[0].id
            res.map((item) => {
                console.log(item)
                console.log(typeof item.id)
                console.log(item.id == shop_id)
            })
            console.log(res.find((item) => item.id == shop_id));
            setShop(response.data.data.find((item) => item.id == shop_id))
            setShopId(shop_id)
            getStockValue(shop_id)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getStockValue = (shop_id) => {
        setIsLoading(true)
        axios.get(`${Config.api_endpoint}/stock/value/${shop_id}`).then(function (response) {
            console.log(response);
            setIsLoading(false)
            let res = response.data.data
            setStockValueInfo(res)
            console.log(res)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const handleShopChange = (e) => {
        console.log(e.target.value)
        setShopId(e.target.value.id)
        setShop(e.target.value)
        getStockValue(e.target.value.id)
    }




    return (
        isLoading ?
            <Loader /> :
            <Box>
                <FormControl variant="standard" sx={{ height: '15px' }}>
                    <InputLabel id="demo-simple-select-label">Select Shop</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"

                        label="Age"

                        value={shop}
                        onChange={handleShopChange}
                        style={{ width: '500px', height: '35px' }}
                        placeholder='Select Shop'
                    >
                        {shopDropdown.map((item) => (
                            <MenuItem value={item}>{item.id}. {item.name} / {item.address}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <br />
                <br />
                <br />
                <br />

                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Box sx={{ border: 1, borderStyle: 'solid' }}>
                            <p>Stock Count = {stockValueInfo.stock_info && stockValueInfo.stock_info.stock_count}</p>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box sx={{ border: 1, borderStyle: 'solid' }}>
                            <p>Stock Value = {stockValueInfo.stock_info && stockValueInfo.stock_info.stock_value}</p>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box sx={{ border: 1, borderStyle: 'solid' }}>
                            <p>Sold Count = {stockValueInfo.sold_info && stockValueInfo.sold_info.sold_count}</p>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box sx={{ border: 1, borderStyle: 'solid' }}>
                            <p>Sold Value = {stockValueInfo.sold_info && stockValueInfo.sold_info.sold_value}</p>
                        </Box>
                    </Grid>
                </Grid>
                <br />
                <br />

                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                            <Tab label="Stock" value="shopwiseStock" />
                            <Tab label="Sales" value="shopwiseSale" />
                        </TabList>
                    </Box>
                    <TabPanel value="shopwiseStock">
                        <SpecificReportTable shopId={shopId} reportType='shopwiseStock' />
                    </TabPanel>
                    <TabPanel value="shopwiseSale">
                        <SpecificReportTable shopId={shopId} reportType='shopwiseSale' />
                    </TabPanel>
                </TabContext>
            </Box>

    );
}