import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TextField, Typography, Select, MenuItem } from '@mui/material';
import { Button } from '@mui/material';
import ProductAddingJson from '../utils/productAdding.json'
import axios from 'axios'
import Config from '../config/config'
import { IconButton } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';


export default function MultilineTextFields() {

    const categoryList = [
        {
            key: 'mobile',
            label: 'Mobile'
        },
        {
            key: 'accessories',
            label: 'Accessories'
        },
        {
            key: 'other',
            label: 'Other'
        }
    ]

    const categoryList1 = ['Mobile', 'Accessories', 'Other']

    const [output, setOutput] = useState([])
    const [loadComplete, setLoadComplete] = useState(false)

    useEffect(() => {
        let outputTemp = {}
        ProductAddingJson.map((item) => {
            outputTemp[item.key] = ''
            if (item.inputType === 'dropdown') {
                outputTemp[item.key] = 'Mobile'
            }
        })
        setOutput(outputTemp)
        setLoadComplete(true)
    }, [])

    const saveShopDetails = () => {
        console.log(output)
        axios.post(`${Config.api_endpoint}/create/product`, {
            product: { ...output }
        }).then(function (response) {
            console.log(response);
            window.location.reload(true)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const handleInputChange = (value, key) => {
        console.log(value, key)
        let shopDetails = { ...output }
        shopDetails[key] = value
        setOutput(shopDetails)
    }

    return (
        loadComplete ?
            // <></> : <></> }
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >

                {/* <FormControl> */}

                {/* {AddShopJson.map((row) => (
                    <>
                        <FormLabel>{row.label}</FormLabel>
                        <TextField
                            id={row.key}
                            label={row.label}
                            placeholder={row.placeholder}
                            value={output[row.key]}
                            size='small'
                        />
                    </>
                ))} */}

                <Typography variant='h5' sx={{ paddingBottom: '15px' }}>Add Products</Typography>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                {ProductAddingJson.map((row) => (
                                    <TableCell>
                                        {row.inputType === 'dropdown' ?
                                            <Select
                                                labelId="shopName-select"
                                                id="shopName-select"
                                                value={output[row.key]}
                                                label="shopName"
                                                onChange={(e) => handleInputChange(e.target.value, row.key)}
                                                style={{ width: '300px', height: '40px' }}
                                            >
                                                {categoryList1.map((item) => (
                                                    <MenuItem value={item}>{item}</MenuItem>
                                                ))}
                                            </Select> :
                                            <TextField
                                                id={row.key}
                                                label={row.label}
                                                placeholder={row.placeholder}
                                                value={output[row.key]}
                                                size='small'
                                                onChange={(e) => handleInputChange(e.target.value, row.key)}
                                                style={{ width: '300px' }}
                                            />
                                        }
                                    </TableCell>
                                ))}
                                <TableCell>
                                    <IconButton size='large' onClick={saveShopDetails}>
                                        <CheckCircleOutlineIcon fontSize='large' />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* <Button onClick={saveShopDetails}>Submit</Button> */}
                {/* </FormControl> */}
            </Box>
            : <></>
    );
}