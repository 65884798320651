import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, TextField, Typography } from '@mui/material';
import axios from 'axios'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
// import TextArea from '@mui/material/Texta';
import { IconButton } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { format } from 'date-fns';
import Config from '../../config/config'
import CustomTable from '../Reports/CustomTable'



function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function BasicTable() {

    const [stockInfo, setStockInfo] = useState({
        sold_date: format(new Date(), 'yyyy-MM-dd'),
        imeiList: [],
        imei: ''
    })
    const [shopDropdown, setShopDropdown] = useState([])
    const [imeiInput, setImeiInput] = useState('')
    const [imeiVerifyList, setImeiVerifyList] = useState([])


    useEffect(() => {

        constructInitialValues()
        getShopList()

    }, [])

    const constructInitialValues = () => {
        setStockInfo({
            sold_date: format(new Date(), 'yyyy-MM-dd'),
            imeiList: [],
            imei: ''
        })
        setImeiInput('')
        setImeiVerifyList([])
    }

    const getShopList = () => {
        axios.get(`${Config.api_endpoint}/shops`).then(function (response) {
            console.log(response);
            setShopDropdown(response.data.data)
            // setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const handleImeiChange = (key, event) => {
        // setAge(event.target.value);
        console.log(event.target.value)

        let imeiInputValue = event.target.value

        if (key === 'imeiList' && event.key === 'Enter') {
            // addRow()
            setImeiInput('')
            console.log(stockInfo[key])
            let imeiArray = stockInfo[key] ? stockInfo[key] : []
            
            if((imeiInputValue).includes(" ")){
                let imeiSplitArr = (imeiInputValue).split(" ")
                imeiArray = [...imeiArray, ...imeiSplitArr]
                // imeiArray.push((imeiInputValue).split(" "))
            }else {
                imeiArray.push(imeiInputValue)
            }
            console.log(imeiArray)
            setStockInfo({
                ...stockInfo,
                ['imeiList']: imeiArray
            })
        }
    };

    const handleInputChange = (key, value) => {
        if (key === 'imei') {
            setImeiInput(value)
        } else if (key === 'shop') {
            stockInfo['shop_id'] = value.id
        } else if (key === 'sold_date') {
            stockInfo['sold_date'] = value
        }

    }

    const handleImeiVerify = () => {

        axios.post(`${Config.api_endpoint}/imeiverify`, {
            imeiList: stockInfo.imeiList
        }).then((response) => {
            console.log(response);
            setImeiVerifyList(response.data.data)
        }).catch((error) => {
            console.log(error);
        });

    }

    const handleSubmit = () => {


        let date = stockInfo['sold_date'].split('-')
        let formattedDate = date[2] + '/' + date[1] + '/' + date[0]
        stockInfo['sold_date'] = formattedDate

        axios.put(`${Config.api_endpoint}/stock/sold`, {
            sold_info: { ...stockInfo }
        }).then(function (response) {
            constructInitialValues()
        }).catch(function (error) {
            console.log(error);
        });

    }

    return (
        <>
            <Typography variant='h5' sx={{ paddingBottom: '15px' }}>Sales</Typography>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Sold Date</TableCell>
                            <TableCell>Imei</TableCell>
                            <TableCell>Imei List</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <input type="date" value={stockInfo['sold_date']} onChange={(e) => handleInputChange('sold_date', e.target.value)} style={{ width: '100px', height: '55px' }} />
                            </TableCell>
                            <TableCell>
                                <>
                                    <TextField id='imei'
                                        value={imeiInput}
                                        // autoFocus={Troubleshoot}
                                        onChange={(event) => handleInputChange('imei', event.target.value)}
                                        onKeyDown={(event) => handleImeiChange('imeiList', event)}
                                        style={{ width: '200px', height: '35px' }}>
                                    </TextField>
                                </>
                            </TableCell>
                            <TableCell>
                                <TextareaAutosize
                                    maxRows={4}
                                    value={stockInfo['imeiList'].toString()}
                                    style={{ width: '350px', height: '75px' }}
                                />
                            </TableCell>
                            <TableCell>
                                <IconButton size='large' onClick={handleImeiVerify}>
                                    <CheckCircleOutlineIcon fontSize='large' />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            {imeiVerifyList && imeiVerifyList.length > 0 &&
                <>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Shop Name</TableCell>
                                    <TableCell>Category</TableCell>
                                    <TableCell>Price</TableCell>
                                    <TableCell>Added Date</TableCell>
                                    <TableCell>Split Date</TableCell>
                                    <TableCell>Sold Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {imeiVerifyList.map((product) => (
                                    <TableRow>
                                        <TableCell>{product.id}</TableCell>
                                        <TableCell>{product.name}</TableCell>
                                        <TableCell>{product.shop_name}</TableCell>
                                        <TableCell>{product.category}</TableCell>
                                        <TableCell>{product.price}</TableCell>
                                        <TableCell>{product.added_date}</TableCell>
                                        <TableCell>{product.split_date}</TableCell>
                                        <TableCell>{product.sold_date}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Button onClick={handleSubmit}>Submit</Button>
                </>
            }
            <CustomTable api='stock/allstock' label='All Stock Details' />
        </>
    );
}
