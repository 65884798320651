import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import axios from 'axios';
import Config from '../../config/config'
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';

import { DataGrid } from '@mui/x-data-grid';
import Loader from '../General/Loader'
import './Reports.css'

export default function SpecificReportTable(props) {

    const [apiColumns, setApiColumns] = useState([])
    const [apiRows, setApiRows] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [summaryTable, setSummaryTable] = useState('')


    useEffect(() => {

        initializeAllValues()
        let shop_id = props.shopId
        let type = props.reportType
        console.log(shop_id, type)
        const stock_status = type === 'shopwiseSale' ? 2 : type === 'shopwiseStock' ? 1 : 0
        fetchReport(shop_id, stock_status)

    }, [props.shopId])

    const initializeAllValues = () => {
        setApiColumns([])
        setApiRows([])
        setIsLoading(false)
        setSummaryTable('')
    }

    const toTitle = (string) => {
        return string.replaceAll('_', ' ').toUpperCase()
    }

    const calculateWidth = (input) => {
        let width = 100
        if (input === 'imei') {
            width = 140
        } else if (input.toLowerCase().includes("shop_name")) {
            width = 220
        } else if (input.toLowerCase().includes("mobile")) {
            width = 100
        } else {
            width = input.length * 15
        }
        return width
    }

    const columnVisibility = (input) => {
        let hiddenColumns = ['prod_link', 'shop_link']
        let showColumn = true
        if (hiddenColumns.includes(input)) {
            showColumn = false
        }
        return showColumn
    }

    const fetchReport = async (shop_id, stock_status) => {

        console.log('fetch report table calling');
        setIsLoading(true)
        axios.get(`${Config.api_endpoint}/specificshopsoldtotal?shop_id=${shop_id}&stock_status=${stock_status}`).then(function (response) {
            console.log(response);
            let columnValues = []
            let res = response.data.data[0]
            console.log(response.data)
            console.log(res)
            if (res) {
                response.data && response.data.data && Object.keys(response.data.data[0]).map((item) => {
                    if (columnVisibility(item)) {
                        let json = {
                            field: item,
                            headerName: toTitle(item),
                            width: calculateWidth(item),
                        }
                        columnValues.push(json)
                    }
                })
                setSummaryTable(grouByMobileCount(response.data.data))
                setApiColumns(columnValues)

                let rowValues = []
                response.data && response.data.data && response.data.data.map((item, index) => {
                    let json = {
                        id: index,
                        ...item
                    }
                    rowValues.push(json)
                })
                console.log(rowValues)
                setApiRows(rowValues)
            }

            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });

    }

    const grouByMobileCount = (json) => {
        const mobileCount = {};

        json.forEach((item) => {
            const product_name = item.product_name;
            mobileCount[product_name] = (mobileCount[product_name] || 0) + 1;
        });

        return mobileCount;
    };



    return (
        isLoading ?
            <Loader /> :
            <Box>                
                <Table className='outertable'>
                    <TableBody className=''>
                        <TableCell style={{ width: '50% !important' }}>
                            <DataGrid
                                rows={apiRows}
                                columns={apiColumns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 50,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5]}
                                // checkboxSelection
                                disableRowSelectionOnClick
                            />
                        </TableCell>
                        <TableCell style={{ width: '50% !important' }}>
                            <Table className=''>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className='specificreportcell'>Product Name</TableCell>
                                        <TableCell className='specificreportcell'>Total</TableCell>
                                    </TableRow>
                                    {summaryTable && Object.entries(summaryTable).map(([key, value]) => (
                                        <TableRow>
                                            <TableCell className='specificreportcell'>{key}</TableCell>
                                            <TableCell className='specificreportcell'>{value}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableCell>
                    </TableBody>
                </Table>
            </Box>

    );
}