import React, { useEffect, useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import Config from '../../config/config'
import { Typography } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Loader from '../General/Loader'


export default function MultilineTextFields() {


    const reportList = [
        {
            "type": "distributor",
            "label": "ItemWise Own Stock",
            "api": "stock/distributor"
        },
        {
            "type": "itemwiseStock",
            "label": "ItemWise Shop Stock",
            "api": "stock/itemwiseStock"
        },
        {
            "type": "itemwiseSale",
            "label": "ItemWise Sold Stock",
            "api": "stock/itemwiseSale"
        },
        {
            "type": "shopwiseSale",
            "label": "ShopWise Sold Stock",
            "api": "stock/shopwiseSale"
        },
        {
            "type": "shopwiseStock",
            "label": "ShopWise Hold Stock",
            "api": "stock/shopwiseStock"
        },
        {
            "type": "allStock",
            "label": "IMEI",
            "api": "stock/allstock"
        }
    ]

    const [apiResponse, setApiResponse] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [apiColumns, setApiColumns] = useState([])
    const [apiRows, setApiRows] = useState([])
    const [report, setReport] = useState(reportList[0].type)
    const [reportTitle, setReportTitle] = useState(reportList[0].label)
    const [tabValue, setTabValue] = useState(reportList[0].type);

    useEffect(() => {

        fetchReport(reportList[0])
    }, [])

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);

        let selectedReport = reportList.find((item => item.type === newValue))

        setReport(newValue)
        setReportTitle(selectedReport.label)
        fetchReport(selectedReport)
    };

    const toTitle = (string) => {
        return string.replaceAll('_', ' ').toUpperCase()
    }

    const calculateWidth = (input) => {
        let width = 100
        if (input === 'imei') {
            width = 140
        } else if (input.toLowerCase().includes("shop_name")) {
            width = 220
        } else if (input.toLowerCase().includes("mobile")) {
            width = 100
        } else {
            width = input.length * 15
        }
        return width
    }

    const columnVisibility = (input) => {
        let hiddenColumns = ['prod_link', 'shop_link']
        let showColumn = true
        if (hiddenColumns.includes(input)) {
            showColumn = false
        }
        return showColumn
    }

    const handleReportSelection = (value) => {
        console.log(value)

        let selectedReport = reportList.find((item => item.type === value))

        setReport(value)
        setReportTitle(selectedReport.label)
        fetchReport(selectedReport)

    }

    const fetchReport = async (value) => {

        setIsLoading(true)
        axios.get(`${Config.api_endpoint}/${value.api}`).then(function (response) {
            console.log(response);
            setApiResponse(response.data.data)
            let columnValues = []
            response.data && response.data.data && Object.keys(response.data.data[0]).map((item) => {
                if (columnVisibility(item)) {
                    let json = {
                        field: item,
                        headerName: toTitle(item),
                        width: calculateWidth(item),
                    }
                    columnValues.push(json)
                }
            })
            setApiColumns(columnValues)

            let rowValues = []
            response.data && response.data.data && response.data.data.map((item, index) => {
                let json = {
                    id: index,
                    ...item
                }
                rowValues.push(json)
            })
            console.log(rowValues)
            setApiRows(rowValues)
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });

    }

    const handleRowClick = (params) => {
        console.log(params)
        console.log('row click')
        const queryString = Object.entries(params.row)
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join('&');
        console.log(queryString)
        if (report === 'shopwiseStock' || report === 'shopwiseSale') {
            let encoded = `${encodeURIComponent('shop_id')}=${encodeURIComponent(params.row.shop_id)}&${encodeURIComponent('reportType')}=${encodeURIComponent(report)}`
            window.open(`/specificreport?${encoded}`, '_blank');
        }
    }

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <Typography variant='h5' sx={{ paddingBottom: '15px' }}>Reports</Typography>
            {/* <Select
                id="productName-select"
                value={report}
                placeholder='Report Type'
                onChange={(e) => handleReportSelection(e.target.value)}
                style={{ width: '200px', height: '60px' }}
            >
                {reportList.map((item) => (
                    <MenuItem value={item.type}>{item.label}</MenuItem>
                ))}

            </Select> */}
            <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                        {reportList.map((item) => (
                            <Tab label={item.label} value={item.type} />
                        ))}
                    </TabList>
                </Box>
                {/* <TabPanel value="shopwiseStock">
                </TabPanel>
                <TabPanel value="shopwiseSale">
                </TabPanel> */}
            </TabContext>
            <br />
            {isLoading ?
                <Loader /> :
                <DataGrid
                    onRowClick={handleRowClick}
                    rows={apiRows}
                    columns={apiColumns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 50,
                            },
                        },
                    }}
                    pageSizeOptions={[5]}
                    // checkboxSelection
                    disableRowSelectionOnClick
                    isColumnResizing
                    // slots={{
                    //     toolbar: GridToolbar,
                    // }}
                />
            }

            {/* <TabContext value={report}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleReportSelection} aria-label="lab API tabs example">
                        {reportList.map((item) => (
                            <Tab label={item.label} value="3" />
                        ))}
                    </TabList>
                </Box>
                {reportList.map((item) => (
                    <TabPanel value={item.label}>

                        <DataGrid
                            rows={apiRows}
                            columns={apiColumns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5,
                                    },
                                },
                            }}
                            pageSizeOptions={[5]}
                            // checkboxSelection
                            disableRowSelectionOnClick
                        />
                    </TabPanel>
                ))}
            </TabContext> */}



            {/* <MaterialReactTable table={table} /> */}


            {/*  
            {apiResponse && apiResponse.map((item) => (
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableBody>
                            <TableRow>

                                <TableCell>
                                    <TextField
                                        id={row.key}
                                        label={row.label}
                                        placeholder={row.placeholder}
                                        value={output[row.key]}
                                        size='small'
                                        onChange={(e) => handleInputChange(e.target.value, row.key)}
                                        style={{ width: '300px' }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <IconButton size='large' onClick={saveShopDetails}>
                                        <CheckCircleOutlineIcon fontSize='large' />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            ))}

             */}
        </Box>
    );
}